<template>
  <div class="profile-container">
    <b-row class="align-items-center justify-content-between g-0">
      <b-col class="text-center" cols="auto">
        <div style="position: relative; display: inline-block;">
          <img :src="require('../../../../../../../assets/images/login1.png')" alt="Institution Logo"
            class="img-fluid mx-auto d-block"
            style="width: 60px; height: auto; border-radius: 10%; filter: brightness(0);" />
          <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; 
               background-color: #add8e6; mix-blend-mode: screen; 
               border-radius: 10%; pointer-events: none;">
          </div>
        </div>
      </b-col>
      <b-col class="text-center" cols="auto">
        <p class="institution-name" style="font-size: 2.5rem; font-weight: bold; color: #34495e; 
           font-family: 'Georgia', serif; text-transform: uppercase; margin: 0; margin-left: 120px;">
          {{ userSubGroup.institution.name }}
        </p>
      </b-col>
      <b-col class="text-right" cols="auto">
        <b-button class="text-right" variant="info" style="font-size: 1rem; font-weight: 600; padding: 8px 15px; 
             margin-right: 10px; display: inline-flex; align-items: center;" @click="downloadStudentRecord()">Download
        </b-button>
      </b-col>
    </b-row>
    <b-row class="align-items-center justify-content-between g-0">
      <b-col cols="auto" class="d-flex justify-content-center align-items-center"></b-col>
      <b-col cols="auto" class="text-center">
        <span style="font-size: 1.2rem; font-weight: 600; color: #34495e; margin-right: 8px;">
          {{ userSubGroup.usergroup.name }}
        </span>
        <span style="font-size: 1.2rem; font-weight: 600; color: #bdc3c7; margin: 0 8px;">
          |
        </span>
        <span style="font-size: 1.2rem; font-weight: 600; color: #34495e; margin-left: 8px;">
          {{ userSubGroup.usergroup.maingroup.name }}
        </span>
      </b-col>
      <b-col cols="auto" class="d-flex justify-content-end align-items-center"></b-col>
    </b-row>
    <div class="student-profile">
      <!-- Academic Information -->
      <b-card>
        <div class="section-container">
          <!-- Section Header -->
          <b-row align-v="center">
            <b-col>
              <h5 class="text-uppercase text-info font-weight-bold">
                <i class="fas fa-university mr-2"></i> Academic Information
              </h5>
            </b-col>
          </b-row>
          <!-- All Details in One Row -->
          <b-row class="py-1 align-items-center border-bottom">
            <b-col md="2" sm="6" class="capitalize">Admission Number</b-col>
            <b-col md="4" sm="6">{{ institutionUserPersonalDetails['admission_no'] }}</b-col>
            <b-col md="2" sm="6" class="capitalize">Register Number</b-col>
            <b-col md="4" sm="6">{{ institutionUserPersonalDetails['register_no'] }}</b-col>
          </b-row>
        </div>
      </b-card>

      <!-- Personal Details -->
      <b-card>
        <div class="section-container">
          <b-row align-v="center">
            <b-col>
              <h4 class="text-uppercase section-title text-info font-weight-bold mb-0">
                <i class="fas fa-user"></i> Personal Details
              </h4>
            </b-col>
          </b-row>

          <b-row class="py-1 align-items-center border-bottom">
            <b-col md="2" class="capitalize">Name</b-col>
            <b-col md="4">{{ capitalizeFirstLetter(institutionUserPersonalDetails['first_name'])
              }} {{
                capitalizeFirstLetter(institutionUserPersonalDetails['last_name']) }}</b-col>
            <b-col md="2" class="capitalize">Date of Birth</b-col>
            <b-col md="4">{{ formatDate(institutionUserPersonalDetails['birth_date']) }}</b-col>
          </b-row>

          <b-row class="py-1 align-items-center border-bottom">
            <b-col md="2" class="capitalize">Gender</b-col>
            <b-col md="4">{{ capitalizeFirstLetter(institutionUserPersonalDetails['gender'])
              }}</b-col>
            <b-col md="2" class="capitalize">Aadhaar Number</b-col>
            <b-col md="4">{{
              capitalizeFirstLetter(institutionUserPersonalDetails['aadhaar_card_no'])
            }}</b-col>
          </b-row>

          <b-row class="py-1 align-items-center border-bottom">
            <b-col md="2" class="capitalize">Communication Address</b-col>
            <b-col md="10">
              {{ capitalizeFirstLetter(institutionUserPersonalDetails['contact_address']) }},
            </b-col>
          </b-row>

          <b-row class="py-1 align-items-center border-bottom">
            <b-col md="2" class="capitalize">Email</b-col>
            <b-col md="4">{{ institutionUserPersonalDetails['email'] }}</b-col>

            <b-col md="2" class="capitalize">Phone Number</b-col>
            <b-col md="4">{{ institutionUserPersonalDetails['mobile'] }}</b-col>
          </b-row>

          <b-row class="py-1 align-items-center border-bottom">
            <b-col md="2" class="capitalize">Guardian Phone Number</b-col>
            <b-col md="4">{{ institutionUserPersonalDetails['guardian_mobile'] }}</b-col>
            <b-col md="2" class="capitalize">Guardian Email</b-col>
            <b-col md="4">{{ institutionUserPersonalDetails['guardian_email'] }}</b-col>
          </b-row>
        </div>
      </b-card>
      <b-card>
        <!-- Academic Details -->
        <div class="section-container">
          <b-row align-v="center">
            <b-col md="12">
              <h5 class="text-uppercase text-info font-weight-bold" style="display: flex; align-items: center">
                <i class="fas fa-book"></i> Academic Details
              </h5>
            </b-col>
          </b-row>
          <b-row v-if="studentAcademicDetails && studentAcademicDetails.length > 0" align-v="center" class="mb-3">
            <b-col md="12" v-for="(academicDetails, outerIndex) in studentAcademicDetails" :key="outerIndex">
              <div class="text-center mb-4">
                <h2
                  style="font-size: 2.5rem; font-weight: 600; color: #2C3E50; line-height: 1.4; margin-bottom: 0.5rem;">
                  {{ academicDetails.usersubgroup_name }}
                </h2>
                <p style="font-size: 1.25rem; font-weight: 500; color: #7F8C8D; margin-top: 0; margin-bottom: 1.5rem;">
                  {{ academicDetails.academic_year }} - {{ academicDetails.academic_semester }}
                </p>
              </div>
              <div class="table">
                <table class="table table-striped table-bordered mb-0"
                  style="background-color: #ffffff; border-radius: 8px; border: 1px solid #ddd;">
                  <thead class="thead-dark text-left" style="background-color: #34495E; color: white;">
                    <tr>
                      <th>Course Code</th>
                      <th>Course Name</th>
                      <th>Attendance (%)</th>
                      <th v-for="(exam, examIndex) in academicDetails.internalExamNames" :key="examIndex">
                        {{ exam.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) }}
                      </th>
                      <th>Final Internal Marks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(course) in academicDetails.courses" :key="course.courseId">
                      <td>{{ course.courseCode || 'N/A' }}</td>
                      <td>{{ course.courseName || 'N/A' }}</td>
                      <td>
                        {{ course.generateInternalEvaluationReport ? (course.attendancePercentage || 'N/A') : '' }}
                      </td>

                      <!-- Marks for each internal_exam_names -->
                      <td v-for="(examName, examIndex) in academicDetails.internalExamNames" :key="examIndex">
                        <span
                          v-if="course.generateInternalEvaluationReport && getActivityMark(course.activities, examName)">
                          {{
                            getActivityMark(course.activities, examName).mark || 'N/A'
                          }}/ {{
                            getActivityMark(course.activities, examName).maxMark || 'N/A'
                          }}
                        </span>
                      </td>

                      <td>
                        {{ course.generateInternalEvaluationReport ? (course.totalMarks || 'N/A') : '' }}
                        <span v-if="course.generateInternalEvaluationReport">/</span>
                        {{ course.generateInternalEvaluationReport ? (course.maxMarks || 'N/A') : '' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["groupInfo", "institutionUserIds", "sortByOrder"],
  data() {
    return {
      institutionUserPersonalDetails: [],
      userSubGroup: [],
      studentAcademicDetails: []
    };
  },
  async created() {
    await Promise.all([
      this.getSubGroup(),
      this.getAcademicDetails(),
    ]);
  },
  computed: {
    getActivityMark() {
      return (activities, examName) => {
        const activity = activities?.find(activity => activity.name === examName);
        return activity || null;
      };
    }
  },
  methods: {
    capitalizeFirstLetter(text) {
      if (!text) return '';
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    formatDate(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = new Date(date).toLocaleDateString('en-GB', options); // 'en-GB' format: dd/mm/yyyy
      return formattedDate;
    },
    async downloadStudentRecord() {
      this.loadingPdf = true;
      const url =
        `${this.$store.getters.getAPIKey.mainAPI}/usersubgroupuser/academicdetails/pdfexport`;

      const params = {
        institution_user_id: this.institutionUserIds[0],
        usersubgroup_id: this.groupInfo.id
      };

      await this.$axios
        .post(url, params, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "student academic details.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;
        })
        .catch(() => {
          this.$toast.error('Failed to download', {
            position: "top",
            duration: 7000,
          });
        });
      this.loadingPdf = false;
    },
    async getAcademicDetails() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroupuser/academicdetails`;
      const data = {
        usersubgroup_id: this.groupInfo.id,
        institution_user_id: this.institutionUserIds[0],
      };

      try {
        const response = await this.$axios.post(url, data);
        this.studentAcademicDetails = response.data.studentAcademicDetails;
        this.institutionUserPersonalDetails = response.data.institutionUserPersonalDetails;
      } catch (error) {
        console.error(error);
      }
    },
    async getSubGroup() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroups/${this.groupInfo.id}?all_data=1`;
      try {
        const response = await this.$axios.get(url);
        this.userSubGroup = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.institution-name {
  font-size: 50rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  letter-spacing: 4px;
  text-transform: uppercase;
  transform: scale(1.5);
}

.section-container {
  padding: 14px;
  background-color: #f9f9f9;
  border-radius: 0px;
}

.capitalize {
  text-transform: capitalize;
}
</style>