<template>
  <div>
    <b-row no-gutters v-if="screenWidth <= 600">
      <b-col>
        <institute-mobile-view :selectedInstitute="selectedInstitute" :usersList="usersList" :groupList="groupList">
        </institute-mobile-view>
      </b-col>
    </b-row>

    <div v-if="screenWidth > 600">
      <b-container fluid class="p-0">
        <div class="loading" v-if="fetchData || loadInstitutes">
          <load-data></load-data>
        </div>

        <div id="instituteTabs" v-else>
          <div v-if="academicYearWarning">
            <b-alert v-if="$root.default_academic_year[0] == '0'" class="p-1 m-0 text-center" show variant="danger">
              <span style="font-size:14px !important;"> Please select correct academic year to view data in proper
                order</span>
            </b-alert>
          </div>
          <b-collapse id="mainContent-collapse" :visible="$root.subContent.enableMainContent">
            <div class="d-flex justify-content-between align-items-center  p-3">
              <div class="d-flex align-items-center">
                <p class="alert alert-danger mb-1" v-if="selectedInstituteRole == null">
                  Please Select a role!
                </p>
                <div class="selectRole" v-if="myIinstitutionEnrollmets.length != 0">
                  <b-form-select :options="institutionRolesOptions" v-model="selectedInstituteRole"
                    @change="changeInstituteRole" class="text-capitalize" style="width: 200px"></b-form-select>
                </div>
                <div class="ml-3">
                  <SetAcedemicYear lazy />
                </div>
                <div class="ml-3">
                  <EnrollUserToInstitute lazy />
                </div>
              </div>
              <div class="d-flex" id="instiConfig">
                <InstituteInvite lazy @invitationClosed="inviteInstitutionUsers = false" v-if="inviteInstitutionUsers"
                  ref="inviteUsers" :selectedInstitute="selectedInstitute" />
                <b-button variant="outline-primary" size="sm" class="d-flex align-items-center bg-white"
                  v-if="instituitionAdmin" @click="inviteUsers()"><i class="fas fa-user-plus mr-1"></i>Invite Users
                </b-button>
                <b-button variant="outline-primary" size="sm" class="d-flex align-items-center bg-white ml-2"
                   @click="courseCoverageReport()">Course Coverage Report
                </b-button>

                <b-button variant="outline-primary" size="sm" class="d-flex align-items-center ml-2 bg-white"
                  v-if="sendInfo" @click="$router.push({ name: 'sendInfo' })"><i class="far fa-envelope mr-1"></i>Send
                  Email</b-button>
                <b-button variant="outline-primary" size="sm" @click="$store.commit('setInstituteSettings')"
                  class="d-flex align-items-center bg-white ml-2" v-if="instituitionAdmin"><i
                    class="fas fa-cog mr-1"></i>Institute Config</b-button>
              </div>
            </div>
            <!-- attendace count cards in dashboard   -->
            <b-container v-if="instituitionAdmin || mainGroupAdmin || userGroupAdmin || subgroupAdmin"
              class=" p-2 mb-3 " style="padding: 30px; ">
              <DashboardCards lazy />
            </b-container>
            <b-tabs id="staffListTab" v-model="tabIndex" lazy v-if="selectedInstituteRole != null">
              <b-tab title="Staff, Student List" id="admin" v-if="usersList">
                <users-list-pending ref="institutionUsers" :selectedInstitute="selectedInstitute"></users-list-pending>
              </b-tab>

              <!-- <b-tab title="User Permissions" id="admin" v-if="usersList">
              <user-permissions />
            </b-tab> -->

              <!-- <b-tab title="My Schedule" id="instructorAccess" v-if="mySchedule">
                <my-schedule @markAttendenceInfo="markAttendenceInfo"></my-schedule>
              </b-tab> -->
              <b-tab title="My Courses" class="instructorAccess" id="instructorAccess" v-if="myCourses"
                @click="editCourseComponents = true">
                <my-courses :editCourseComponents="editCourseComponents"></my-courses>
              </b-tab>

              <b-tab title="Activity" id="instructorAccess" v-if="assessmentTab">
                <Exams lazy />
              </b-tab>

              <b-tab title="University Exam" id="admin" v-if="universityExam">
                <UniversityExamListing lazy />
              </b-tab>

              <!-- <b-tab title="TimeTable" id="admin" v-if="institutionTimetable">
                <institution-time-table></institution-time-table>
              </b-tab> -->

              <b-tab title="TimeTable" id="admin" v-if="institutionTimetable">
                <new-schedule></new-schedule>
              </b-tab>
              <b-tab title="Audit Access Management" id="admin" v-if="auditorIqacComponantPermission">
                <auditorsManagementDashboard/>
              </b-tab>
              <!-- <b-tab title="Fee Management" id="admin" v-if="instituitionAdmin">
                <feeManagementDashboard/>
              </b-tab> -->
              <b-tab title="Instructor Schedule" id="admin" v-if="instructorSchedule">
                <instructor-schedule></instructor-schedule>
              </b-tab>
              <b-tab title="Reports" id="admin" v-if="reportsTab">
                <ReportsOptions lazy />
              </b-tab>

              <!-- <b-tab title="Attendance" href="#markAttendence" class="attendenceTab" v-if="attendenceTab">
                <attendence-marking ref="attendenceMarking" :attendenceInfo="attendenceInfo"></attendence-marking>
              </b-tab> -->
              <b-tab title="Attendance" href="/markAttendence" class="attendenceTab" v-if="attendenceTab">
                <new-attendence-marking ref="attendenceMarking"></new-attendence-marking>
              </b-tab>

              <b-tab title="Live Class" id="instructorAccess" v-if="liveClassTab">
                <live-class></live-class>
              </b-tab>

              <b-tab title="Survey" id="instructorAccess" v-if="surveyTab">
                <ListingSurveys lazy />
              </b-tab>

              <b-tab id="instructorAccess" v-if="viewSurveyApprovaTab">
                <template #title>
                  {{ applicationForm ? "Application Forms" : "Approvals" }}
                  <span v-if="
                    !applicationForm &&
                    $store.getters.getApprovalStatus.formsApprovals.status
                  " class="text-success ml-1 approvalStatus"><i class="fas fa-circle text-success"></i></span>
                </template>
                <Approvals lazy />
              </b-tab>

              <b-tab :title="getGroupLevelNames" id="admin" v-if="mainGroupAdmin || userGroupAdmin">
                <Usergroup lazy :getCustomNames="getCustomNames" :selectedInstitute="selectedInstitute" />
              </b-tab>

              <b-tab :title="
                (getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name
                  : 'Usersubgroup') + '/Courses'
              " id="admin" v-if="mainGroupAdmin || userGroupAdmin || subgroupAdmin">
                <Usersubgroup lazy :getCustomNames="getCustomNames" :selectedInstitute="selectedInstitute" />
              </b-tab>

              <!-- <b-tab
              title="Courses"
              id="admin"
              v-if="viewCoursesTab"
              @click="editCourseComponents = false"
              ><course-listing :editCourseComponents="editCourseComponents">
              </course-listing
            ></b-tab> -->
              <b-tab title="Attendance" id="admin" v-if="studentTab">
                <StudentAttendence lazy />
              </b-tab>
              <b-tab title="My Courses" id="admin" v-if="studentTab">
                <StudentCourses lazy :editCourseComponents="editCourseComponents" :getCustomNames="getCustomNames" />
              </b-tab>
              <b-tab :title="
                getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name
                  : 'Usersubgroup'
              " id="admin" v-if="studentTab">
                <Semester lazy :getCustomNames="getCustomNames" />
              </b-tab>
              <b-tab title="Hostel" id="admin" v-if="usersList">
                <HostelDashboard />
              </b-tab>
            </b-tabs>

          </b-collapse>
        </div>

      </b-container>
    </div>


    <b-collapse id="subContent-collapse" :visible="!$root.subContent.enableMainContent">
      <b-row class="ml-5 no-print subContentComponentCloseButton">
        <b-button size="sm"
          @click="$root.subContent.enableMainContent = true; $root.subContent.subContentComponent = '';"
          variant="danger">Close</b-button>
      </b-row>
      <ExamEvaluation v-if="$root.subContent.subContentComponent == 'ActivityEvaluation'"
        :exam_id="$root.subContent.subContentData.exam_id" lazy />
      <editCourseAttendanceFormWithReport
        v-if="$root.subContent.subContentComponent == 'editCourseAttendanceFormWithReport'" lazy />
      <ManageExamQuestions v-if="$root.subContent.subContentComponent == 'ManageExamQuestions'" lazy :exam="$root.subContent.subContentData.exam" />
      <CourseCoverageMonitoring v-if="$root.subContent.subContentComponent == 'CourseCoverageMonitoring'" lazy  />
      <ManageSubgroupFeeStructure v-if="$root.subContent.subContentComponent == 'ManageSubgroupFeeStructure'" lazy :usergroup="$root.subContent.subContentData.usergroup" :financeCatagories="$root.subContent.subContentData.financeCatagories" />
      <ViewProfile 
        v-if="$root.subContent.subContentComponent == 'ViewProfile'" 
        lazy 
        :groupInfo="$root.subContent.subContentData.groupInfo" 
        :institutionUserIds="$root.subContent.subContentData.institutionUserIds" 
        :sortByOrder="$root.subContent.subContentData.sortByOrder" />
      <!-- <ExamQuestionPaperPreview v-if="$root.subContent.subContentComponent == 'ExamQuestionPaperPreview'" lazy 
      :exam="$root.subContent.subContentData.exam"
      :questionsAndGroupsAndSectionView="$root.subContent.subContentData.questionsAndGroupsAndSectionView"
        :groupObjects="$root.subContent.subContentData.groupObjects"  :questionObjects="$root.subContent.subContentData.questionObjects" :sectionObjects="$root.subContent.subContentData.sectionObjects" /> -->
    </b-collapse>
    <br />
  </div>
</template>

<script>
import editCourseAttendanceFormWithReport from "../attendence/editCourseAttendanceFormWithReport.vue";
import HostelDashboard from "../../../hostel/dashboard.vue";
import ExamEvaluation from "../examination/typeofexam/TypeOfExam.vue";
import ManageExamQuestions from "../examination/manageExamQuestions.vue";
import ViewProfile from './usergroups/usersubgroups/subgroup_users_edit/ViewProfile.vue';
// import ExamQuestionPaperPreview from "../examination/questionPaperPreview.vue";
import UsersListPending from "../Institute/institution_users/UsersListPending.vue";
// import InstituteGroups from "./usergroups/InstituteGroups.vue";
// import CourseListing from "../Course/CourseListing.vue";
// import AttendenceMarking from "../attendence/AttendenceMarking.vue";
import NewAttendenceMarking from "../attendence/NewAttendenceMarking.vue";
// import MySchedule from "../timetable/MySchedule.vue";
import InstitutionMobileView from "../Institute/InstitutionMobileView.vue";
import MyCourses from "../Course/MyCourses.vue";
import ListingLiveClasses from "../live_class/ListingLiveClasses.vue";
// import InstitutionTimeTable from "../timetable/InstitutionTimeTable";
import NewSchedule from "../timetable/NewScheduler";
import InstructorSchedule from "../timetable/InstructorSchedule";
import ListingSurveys from "../survey/ListingSurveys.vue";
import Approvals from "../survey/survey_approvals/Approvals.vue";
import Exams from "../examination/Exams.vue";
// import userPermissions from "./userpermissions/userPermissions.vue";
import ReportsOptions from "../reports/ReportsOptions.vue";
import SetAcedemicYear from "./settings/SetAcedemicYear.vue";
import UniversityExamListing from "../university_exam/UniversityExamListing.vue";
import StudentAttendence from "../attendence/StudentAttendence.vue";
import InstituteInvite from "./invite/InstituitionInvite.vue";
import EnrollUserToInstitute from "./settings/EnrollUserToInstitute.vue";
import Usergroup from "../usergroup/MaingroupAndGroupTabs";
import Usersubgroup from "../usersubgroup/Usersubgroup";
import StudentCourses from "../student/MyCourses";
import Semester from "../student/Semester";
import DashboardCards from "../../../dashboardCards/cards.vue";
import acl from "../../../../authorization/acl.js";
import auditorsManagementDashboard from "../../../auditor/dashboard.vue";
// import feeManagementDashboard from "../../../finance/dashboard.vue";
import ManageSubgroupFeeStructure from "../../../finance/manageSubgroupFeeStructure.vue";
import CourseCoverageMonitoring from "../Course/lesson_plan/CourseCoverageMonitoring.vue";
export default {
  props: [
    "selectedInstitute",
    "instituteSubTab",
    "myIinstitutionEnrollmets",
    "filteredUniqueInstitutions",
    "defaultInstituteId",
    "instituitionAdmin",
    "sendInfo",
    "defaultInstitutionUserId",
    "canInviteUsers",
  ],
  async mounted() {
    this.fetchData = true;
    await this.getInstitutionRoles();
    await window.addEventListener("resize", this.handleResize);
    await this.handleResize();
    await this.handlePermissions();

    this.fetchData = false;
  },
  async created() {
    let auditor = await acl.isUserGranted("manageAuditors") ;
    let iqac = await acl.isUserGranted("manageIqacStaff") ;
    this.auditorIqacComponantPermission = auditor || iqac ;
    this.checkAcademicYearWarning();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
    usersListPending: UsersListPending,
    // InstituteGroups: InstituteGroups,
    // CourseListing: CourseListing,
    // attendenceMarking: AttendenceMarking,
    // mySchedule: MySchedule,
    myCourses: MyCourses,
    instituteMobileView: InstitutionMobileView,
    liveClass: ListingLiveClasses,
    // institutionTimeTable: InstitutionTimeTable,
    NewSchedule,
    instructorSchedule: InstructorSchedule,
    ListingSurveys,
    Exams,
    // UserPermissions: userPermissions,
    Approvals,
    ReportsOptions,
    SetAcedemicYear,
    UniversityExamListing,
    StudentAttendence,
    InstituteInvite,
    EnrollUserToInstitute,
    Usergroup,
    Usersubgroup,
    StudentCourses,
    Semester,
    DashboardCards,
    ExamEvaluation,
    ManageExamQuestions,
    // ExamQuestionPaperPreview,
    HostelDashboard,
    NewAttendenceMarking,

    editCourseAttendanceFormWithReport,
    auditorsManagementDashboard,
    // feeManagementDashboard,
    ManageSubgroupFeeStructure,
    CourseCoverageMonitoring,
    ViewProfile,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ["#markAttendence"],
      screenWidth: 0,
      mobileView: false,
      // auditorsManagementTab:false,
      usersList: null,
      groupList: null,
      viewCoursesTab: null,
      myCourses: null,
      institutionTimetable: null,
      attendenceTab: null,
      studentTab: null,
      mySchedule: null,
      instructorSchedule: null,
      assessmentTab: null,
      liveClassTab: null,
      assignmentTab: null,
      reportsTab: null,
      surveyTab: null,
      viewSurveyApprovaTab: null,
      applicationForm: null,
      universityExam: null,
      attendenceInfo: {},
      fetchData: false,
      viewType: "Assessment",
      institutionRolesOptions: [],
      selectedInstituteRole: null,
      editCourseComponents: false,
      componentHeight: "970px",
      inviteInstitutionUsers: false,
      mainGroupAdmin: null,
      userGroupAdmin: null,
      subgroupAdmin: null,
      tabColor: "black",
      surveyApprovals: [],
      userRolesObject: {},
      academicYearWarning: false,
      auditorIqacComponantPermission:false,
    };
  },
  computed: {
    customName() {
      return this.$store.getters.getCustomNamings;
    },
    loadInstitutes() {
      return this.$store.getters.getInstituteStatus;
    },
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    getGroupLevelNames() {
      if (
        this.getCustomNames.mainGroup.custom_name != null &&
        this.getCustomNames.userGroup.custom_name == null
      ) {
        return this.getCustomNames.mainGroup.custom_name + "/" + "Usergroup";
      } else if (
        this.getCustomNames.mainGroup.custom_name == null &&
        this.getCustomNames.userGroup.custom_name != null
      ) {
        return "Maingroup" + "/" + this.getCustomNames.userGroup.custom_name;
      } else if (
        this.getCustomNames.mainGroup.custom_name != null &&
        this.getCustomNames.userGroup.custom_name != null
      ) {
        return (
          this.getCustomNames.mainGroup.custom_name +
          "/" +
          this.getCustomNames.userGroup.custom_name
        );
      } else {
        return "Maingroup/Usergroup";
      }
    },
  },
  methods: {
    courseCoverageReport(){
      this.$root.subContent.subContentComponent = "CourseCoverageMonitoring";
      this.$root.subContent.subContentData = { };
      this.$root.subContent.enableMainContent = false;
    },
    checkAcademicYearWarning() {
      setTimeout(
        function () {
          this.academicYearWarning = true;
        }.bind(this),
        4000
      );
    },
    async handlePermissions() {
      const instituitionUsersList = await acl.isUserGranted(
        "viewInstitutionUsers"
      );
      this.usersList = instituitionUsersList;

      const groups = await acl.isUserGranted("viewGroupsSubgroupsTab");
      this.groupList = groups;

      const viewCoursesTab = await acl.isUserGranted("viewCoursesTab");
      this.viewCoursesTab = viewCoursesTab;

      const myCourses = await acl.isUserGranted("myCourses");
      this.myCourses = myCourses;

      const institutionTimetable = await acl.isUserGranted(
        "ViewInstitutionTimeTableTab"
      );
      this.institutionTimetable = institutionTimetable;

      const attendenceTab = await acl.isUserGranted("viewManageAttendanceTab");
      this.attendenceTab = attendenceTab;

      const studentTab = await acl.isUserGranted("viewStudentTab");
      this.studentTab = studentTab;

      const mySchedule = await acl.isUserGranted("mySchedule");
      this.mySchedule = mySchedule;

      const instructorSchedule = await acl.isUserGranted(
        "ViewInstructorTimeTableTab"
      );
      this.instructorSchedule = instructorSchedule;

      const assessmentTab = await acl.isUserGranted("viewAssessmentTab");
      this.assessmentTab = assessmentTab;

      const viewLiveClassTab = await acl.isUserGranted("viewLiveClassTab");
      this.liveClassTab = viewLiveClassTab;

      const viewAssignmentTab = await acl.isUserGranted("viewAssignmentTab");
      this.assignmentTab = viewAssignmentTab;

      const viewReportsTab = await acl.isUserGranted("viewReportsTab");
      this.reportsTab = viewReportsTab;

      const viewSurveyTab = await acl.isUserGranted("viewSurveyTab");
      this.surveyTab = viewSurveyTab;

      const viewSurveyApprovaTab = await acl.isUserGranted(
        "viewSurveyApprovalTab"
      );

      // this.auditorsManagementTab = await acl.isUserGranted(
      //   "viewInstitutionUsers"
      // ); // as admin

      this.viewSurveyApprovaTab = viewSurveyApprovaTab;

      const applicationForm = await acl.isUserGranted("SurveyApprovalStudent");
      this.applicationForm = applicationForm;

      const universityExam = await acl.isUserGranted("universityExam");
      this.universityExam = universityExam;

      this.mainGroupAdmin = await acl.isUserGranted("mainGroupAdmin");
      this.userGroupAdmin = await acl.isUserGranted("groupAdmin");
      this.subgroupAdmin = await acl.isUserGranted("subgroupAdmin");

      await this.getFormsForApproversStatus();
    },
    async getFormsForApproversStatus() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/onlineregistrations/appforapproval/status`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.$store.state.formsApprovals.status =
            response.data == 1 ? true : false;
        })
        .catch(() => { });
    },
    async inviteUsers() {
      this.inviteInstitutionUsers = await true;
      await this.inviteInstitutionUsers;
      await this.$refs.inviteUsers.prepareInviteUsers();
    },

    async getInstitutionRoles() {
      this.selectedInstituteRole = null;
      this.institutionRolesOptions = [
        { value: null, text: "Select Role", disabled: true },
      ];

      await this.myIinstitutionEnrollmets.forEach((institute) => {
        if (this.selectedInstitute.id == institute.institution.id) {
          this.$set(
            this.userRolesObject,
            institute.id,
            institute.institution_role.name == "teacher"
              ? "Faculty"
              : institute.institution_role.name.charAt(0).toUpperCase() +
              institute.institution_role.name.slice(1)
          );

          if (Number(this.defaultInstitutionUserId) == institute.id) {
            this.selectedInstituteRole = this.defaultInstitutionUserId;
            this.$store.state.userRole = this.userRolesObject[
              this.selectedInstituteRole
            ];
          }
          this.institutionRolesOptions.push({
            value: institute.id,
            text:
              institute.institution_role.name == "teacher"
                ? this.selectedInstituteRole == institute.id
                  ? "User Role: Faculty"
                  : "Faculty"
                : this.selectedInstituteRole == institute.id
                  ? "User Role:" +
                  institute.institution_role.name.charAt(0).toUpperCase() +
                  institute.institution_role.name.slice(1)
                  : institute.institution_role.name.charAt(0).toUpperCase() +
                  institute.institution_role.name.slice(1),
          });
        }
      });
    },
    changeInstituteRole(value) {
      this.$emit("changeInstituteRole", value);
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async markAttendenceInfo(attendenceInfo) {
      const indexFound = this.tabs.findIndex((tab) => tab === this.$route.hash);
      this.tabIndex = indexFound;
      this.attendenceInfo = await attendenceInfo;
      await this.$refs.attendenceMarking.fillAttendenceDate();
      this.$forceUpdate();
    },
  },
};
</script>

<style >
#instituteTabs .approvalStatus i {
  font-size: 10px !important;
}

#instiConfig button {
  color: #5bc0de;
  display: flex;
  justify-content: center;
}

#instiConfig button:hover {
  background-color: #5bc0de !important;
  color: #ffffff;
}

#instituteTabs .nav-tabs {
  border-bottom: none !important;
}

#staffListTab .tab-content {
  border: 8px solid #d8d8d8;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 10px;
}

.groupName .list-group-item,
.groupAdminName .list-group-item,
.hideSubgroups .list-group-item {
  border-style: none;
}

#staffListTab__BV_tab_container_ {
  min-height: 70vh;

  padding: 10px;
}

#instituteTabs ul {
  border-radius: none;
}

#instituteTabs ul li {
  border: 3px solid #eeeeeb;
}

#instituteTabs ul li a {
  font-size: 12px !important;
  width: 175px;
  text-align: center;
}

#instituteTabs .nav-tabs .nav-link {
  background-color: #1ea2ed;
  color: #ffffff;
}

#instituteTabs .nav-tabs #admin___BV_tab_button__ {
  background-color: #288745;
  color: #ffffff;
}

#instituteTabs .nav-tabs #admin___BV_tab_button__.active {
  background-color: white !important;
  color: #288745 !important;
}

#instituteTabs .nav-tabs .nav-link.active {
  background-color: white;
  color: #077bff;
}

#courseFeatures .nav-tabs .nav-link.active {
  background-color: #077bff;
  color: #ffffff;
}
</style>
