<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="sessions" v-else>
      <h5 class="font-weight-bolder">Configure/add sessions</h5>
      <b-button variant="primary" size="md" class="ml-4 mt-2" v-if="addSession == false"
        @click="addSession = !addSession">Add Session</b-button>
      <div class="mt-2" ref="sessionForm"></div>
      <b-form class="mt-4" v-if="addSession" @submit.prevent="createSession()">
        <b-form-group class="text-center text-info">
          <h3 style="font-weight: 600; font-size: 18px">Create Session</h3>
        </b-form-group>
        <b-form-group>
          <span class="alert alert-success text-center" v-if="responseErrors.sessionCreated">{{
              responseErrors.sessionCreated[0]
          }}</span>
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-center flex-wrap">
            <div>
              <b-input :disabled="sessionId != ''" placeholder="Session Name" style="width: 200px"
                v-model="sessions.name" :class="{ 'is-invalid': responseErrors.name }"></b-input>
              <span class="text-danger" v-if="responseErrors.name">{{
                  responseErrors.name[0]
              }}</span>
            </div>
            <div>
              <b-form-select v-model="sessions.view_order" :value="
                !sessions.view_order
                  ? (sessions.view_order = null)
                  : sessions.view_order
              " :options="viewOrderList" :class="{ 'is-invalid': responseErrors.view_order }" class="ml-3">
              </b-form-select>
              <span class="text-danger" v-if="responseErrors.view_order">{{
                  responseErrors.view_order[0]
              }}</span>
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-baseline flex-wrap">
            <div>
              <b-form-timepicker locale="en" v-model="sessions.time_from" placeholder="From" :show-second="true"
                :class="{ 'is-invalid': responseErrors.time_from }" :format="'hh:ii'"></b-form-timepicker>
              <span class="text-danger" v-if="responseErrors.time_from">{{
                  responseErrors.time_from[0]
              }}</span>
            </div>
            <div>
              <b-form-timepicker locale="en" v-model="sessions.time_to" placeholder="To" :show-second="true"
                :class="{ 'is-invalid': responseErrors.time_to }" class="ml-3"></b-form-timepicker>
              <span class="text-danger" v-if="responseErrors.time_to">{{
                  responseErrors.time_to[0]
              }}</span>
            </div>
          </div>
        </b-form-group>

        <b-form-group>
          <div class="d-flex align-items-center flex-wrap">
            <div>
              <b-form-select v-model="sessions.type" :value="
                !sessions.type
                  ? (sessions.type = null)
                  : sessions.type
              " :options="sessionTypes">
              </b-form-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <b-button type=" submit" variant="primary" size="md" :disabled="sessionBtn.disabled">{{ sessionBtn.text }}
          </b-button>
          <b-button variant="danger" size="md" class="ml-3" :disabled="sessionBtn.disabled" @click="cancel()">Cancel
          </b-button>
        </b-form-group>
      </b-form>

      <b-overlay :show="loadSessions" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="row">
          <!-- Left table for view_order = 0 and negative numbers -->
          <div class="col-md-6">
            <h5 v-if="sessionsWithNonPositiveOrder.length && !addSession">Sessions with view_order ≤ 0</h5>
            <table class="table table-striped table-bordered" v-if="sortedSessions.length && !addSession">
              <thead class="thead-dark text-left">
                <tr>
                  <th>Session Name</th>
                  <th>View Order</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(session, sessionIndex) in sortedSessions" :key="sessionIndex">
                  <td class="align-middle">{{ session.name }}</td>
                  <td class="align-middle">{{ session.view_order }}</td>
                  <td class="align-middle">{{ session.type }}</td>
                  <td class="align-middle">
                    <span @click="getSpecificSession(session.id, undefined, { hideSession: false })" class="btn m-0 p-0 text-info">
                      <i class="far fa-edit"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Right table for view_order > 0 -->
          <div class="col-md-6">
            <h5  v-if="sessionsWithPositiveOrder.length && !addSession">Sessions with view_order > 0</h5>
            <table class="table table-striped table-bordered" v-if="sessionsWithPositiveOrder.length && !addSession">
              <thead class="thead-dark text-left">
                <tr>
                  <th>Session Name</th>
                  <th>View Order</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(session, sessionIndex) in sessionsWithPositiveOrder" :key="sessionIndex">
                  <td class="align-middle">{{ session.name }}</td>
                  <td class="align-middle">{{ session.view_order }}</td>
                  <td class="align-middle">{{ session.type }}</td>
                  <td class="align-middle">
                    <span @click="getSpecificSession(session.id, undefined, { hideSession: false })" class="btn m-0 p-0 text-info">
                      <i class="far fa-edit"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["selectedInstitute"],
  created() {
    this.getSessions();
  },
  data() {
    return {
      loadSessions: false,
      fetchData: false,
      sessionId: "",
      sessions: {},
      viewOrderList: [],
      addSession: false,
      sessionsList: [],
      sessionBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      sessionTypes: [
        { value: null, text: ' Select type' },
        { value: 'forenoon', text: 'forenoon' },
        { value: 'afternoon', text: 'afternoon' },
        { value: 'other', text: 'other' }
      ],
    };
  },
  computed: {
    // Sessions with view_order <= 0
    sessionsWithNonPositiveOrder() {
      return this.sessionsList.filter(session => session.view_order <= 0);
    },
    // Sessions with view_order > 0
    sessionsWithPositiveOrder() {
      return this.sessionsList.filter(session => session.view_order > 0);
    },
    sortedSessions() {
      return this.sessionsWithNonPositiveOrder.slice().sort((a, b) => {
        return a.view_order - b.view_order; // Sort by view_order in ascending order
      });
    }
  },
  methods: {
    createSession() {
      this.sessionBtn.disabled = true;
      this.sessionBtn.text = "Please wait...";
      if (this.sessionId != "") {
        this.updateSession();
      } else {
        const url = this.$store.getters.getAPIKey.createSessions;
        axios
          .post(url, this.sessions)
          .then((response) => {
            this.responseErrors = {
              sessionCreated: [
                this.sessions.name + " is successfully created.",
              ],
            };
            this.getSessions();
            this.clearResponse();
            response;
          })
          .catch((error) => {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
            error;
          });
      }
    },
    getSessions() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.createSessions;
      axios
        .get(url)
        .then((response) => {
          this.sessionsList = [];
          this.sessionsList = response.data;
          this.viewOrderList = [{ value: null, text: "Select Order" }];
          // Adding values -3, -2, -1, and 0 before proceeding to handle session-based values
          this.viewOrderList.push({ value: "-3", text: -3 });
          this.viewOrderList.push({ value: "-2", text: -2 });
          this.viewOrderList.push({ value: "-1", text: -1 });
          this.viewOrderList.push({ value: "0", text: 0 });

          if (this.sessionsList.length == 0) {
            this.viewOrderList.push({ value: "1", text: 1 });
          } else {
            const positiveViewOrderCount = this.sessionsList.filter(session => session.view_order > 0).length;
            for (let i = 1; i <= positiveViewOrderCount+1 ; i++) {
              this.viewOrderList.push({ value: i.toString(), text: i });
            }
          }

          this.fetchData = false;

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getSpecificSession(session_id, hideStatus, hideSession) {
      this.loadSessions = true;
      if (hideSession.hideSession) {
        const url =
          this.$store.getters.getAPIKey.createSessions + `/${session_id}`;
        await axios
          .get(url)
          .then((response) => {
            this.sessionId = response.data.id;
            this.sessions.name = response.data.name;
            this.sessions.view_order = hideStatus.hide;
            this.updateSession();
            response;
          })
          .catch((error) => {
            error;
          });
      } else {
        this.$smoothScroll({
          scrollTo: this.$refs.sessionForm,
          duration: 1000,
          offset: -50,
        });
        const url =
          this.$store.getters.getAPIKey.createSessions + `/${session_id}`;
        await axios
          .get(url)
          .then((response) => {
            this.sessionId = response.data.id;
            this.sessions.name = response.data.name;
            this.sessions.time_from = response.data.time_from;
            this.sessions.time_to = response.data.time_to;
            this.sessions.view_order = response.data.view_order;
            this.addSession = true;
            response;
          })
          .catch((error) => {
            error;
          });
        this.loadSessions = false;
      }
    },
    async updateSession() {
      this.sessionBtn.text = "Updating...";
      this.sessionBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.createSessions + `/${this.sessionId}`;

      await axios
        .put(url, this.sessions)
        .then((response) => {
          this.$toast.success(
            `${this.sessions.name} is successfully updated.`,
            {
              position: "top",
              duration: 3000,
            }
          );
          this.responseErrors = {
            sessionCreated: [this.sessions.name + " is successfully updated."],
          };
          this.workdayId = "";
          this.getSessions();
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
      this.loadSessions = false;
    },

    cancel() {
      this.addSession = false;
      this.sessions = {};
    },
    clearResponse() {
      setTimeout(() => {
        if (this.responseErrors.sessionCreated) {
          this.addSession = false;
          this.sessionId = "";
          this.sessions = {};
        }
        this.sessionId = "";
        this.responseErrors = {};
        this.sessionBtn.text = "Submit";
        this.sessionBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>

<style >
#sessionList .list-group-item {
  width: 150px;
}

#sessionList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  #sessionList .list-group-item {
    width: 100%;
  }

  .sessions button {
    width: 100%;
    height: auto;
    text-align: center;
    margin-left: 0px !important;
  }
}
</style>
