<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <!-- load lab data and if no lab show add lab to course
    and reate new lab and show that lab with create exam
    if lab enabled list questions and add option
    lab evaluation headers tick and max score
    in each lab student vs exprinmrt evaluation table -->

    <b-card header-tag="header" class="">
      <template #header>
        <b-link style="font-size:14px !important;" v-b-toggle.lab-experiments-list-collapse
          class="text-bold">Experiments
          <span v-if="labExperiments.length > 0">({{ labExperiments.length }})</span> </b-link>
      </template>
      <!-- <b-button variant="success" @click="createLabExperiment()">Add experiment</b-button> -->
      <b-collapse id="lab-experiments-list-collapse">
        <div class="text-left">
          <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
            <ul>
              <h5>You have three options to add experiments:</h5>

              <li>
                Copy from University Resources <span
                  style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                  proceed</span>
              </li>
              <li>Copy from previous academic year <span
                  style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                  proceed</span>
              </li>
              <li>Or Enter Manually <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="createLabExperiment()">Click here to
                  proceed</span>
              </li>
            </ul>
            <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-footer hide-header centered size="md">
              <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="course_id"
                @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
            </b-modal>
          </div>
        </div>

        <table class="table  table-striped table-bordered">
          <tr>
            <th>No.</th>
            <th>
              Name
            </th>
            <th>Description</th>
            <th>Procedure</th>
            <th>COs</th>
            <th></th>
          </tr>
          <tr v-for="(labExperiment, expindex) in labExperiments" :key="expindex">
            <td>{{ labExperiment.no }}</td>
            <td>{{ labExperiment.name }}</td>
            <td>{{ labExperiment.description }}</td>
            <td>{{ labExperiment.procedures }}</td>
            <td> <span v-for="(co, coIndex) in labExperiment.course_outcomes" :key="coIndex">
                {{ co.code }},
              </span> </td>
            <td>
              <b-button variant="info" @click="editLabExperiment(labExperiment)">Edit</b-button>
              <b-button variant="danger" class="test-danger"
                @click="deleteLabExperiment(labExperiment)">Delete</b-button>
            </td>
          </tr>
        </table>
      </b-collapse>
    </b-card>

    <b-col class="m-2" v-if="courseLabs.length">
      <h4 class="mt-3 text-center" style="font-size:15px !important;">Evaluation Parameters For Laboratory</h4>
      <div class="mt-4" v-for="(courseLab, reindex) in courseLabs" :key="reindex">
        <b-card class="text-center">

          <template #header>
            <b-link style="font-size:14px !important;"
              v-b-toggle="'lab-' + courseLab.type + '-parameters-list-collapse'" class="text-bold">{{ courseLab.type ==
              'lab'
              ? 'Parameters for laboratory continuous evaluation'
              : courseLab.type == 'internal_exam'
              ? 'Parameters for laboratory Internal Exam'
              : courseLab.type == 'record' ? 'Parameters for laboratory Record'
              : 'Parameters for laboratory Viva Voce' }}</b-link>
          </template>

          <b-collapse :id="'lab-' + courseLab.type + '-parameters-list-collapse'">
            <div v-if="courseLab.type == 'lab'">
              <b-button variant="primary" class="m-2" v-if="enableLabAssessmentEvaluation && labExperiments.length > 0"
                @click="labEvaluationTable(courseLab)">
                Update Score
              </b-button>
              <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
            </div>
            <div v-if="courseLab.type == 'internal_exam'">
              <b-button variant="primary" class="m-2" v-if="enableLabInternalExamEvaluation && labExperiments.length > 0
                " @click="labEvaluationTable(courseLab)">
                Update Score
              </b-button>
              <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
            </div>
            <div v-if="courseLab.type == 'viva_voce'">
              <b-button variant="primary" class="m-2" v-if="enableLabVivaVoceEvaluation && labExperiments.length > 0"
                @click="labEvaluationTable(courseLab)">
                Update Score
              </b-button>
              <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
            </div>

            <div v-if="courseLab.type == 'record'">
              <b-button variant="primary" class="m-2" v-if="enableLabRecordEvaluation && labExperiments.length > 0"
                @click="labEvaluationTable(courseLab)">
                Update Score
              </b-button>
              <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
            </div>

            <b-button variant="info" class="m-2" v-if="!editLabHeaders" @click="editLabHeaders = true">Select/Edit
              Parameters</b-button>
            <b-button variant="success" class="m-2" v-if="editLabHeaders"
              @click="updateLabEvaluationOptions(courseLab.type, courseLab.id)">
              Save
            </b-button>
            <span class="text-danger">
              Please contact Ezygo Support(+91 8330869500, support@ezygo.app) to add additional evaluation parameters as
              per the
              course requirement
            </span>
            <table class="table  table-striped table-bordered">
              <tr>
                <th>Enable</th>
                <th>No.</th>
                <th>
                  Header
                </th>
                <th>
                  Max. score
                </th>
              </tr>
              <tr v-for="(header, hrindex) in labEvaluationOptions[courseLab.type]" :key="hrindex + courseLab.type">
                <td>
                  <b-form-checkbox :disabled="!editLabHeaders" v-model="header.enable"></b-form-checkbox>
                </td>
                <td>
                  <span v-if="!editLabHeaders">{{ header.no }}</span>
                  <b-form-input class="required" v-else v-model="header.no" type="text" placeholder="SI. No">
                  </b-form-input>
                </td>
                <td>
                  <span>{{ hrindex }}</span>
                </td>
                <td>
                  <span v-if="!editLabHeaders">{{ header.score }}</span>
                  <b-form-input class="required" v-else v-model="header.score" type="text" placeholder="Max. score">
                  </b-form-input>
                </td>
              </tr>
            </table>
          </b-collapse>
        </b-card>
      </div>
    </b-col>

    <b-col class="addLab m-2">
      <b-card class="text-center mt-2" v-if="courseLabs.length == 0 && courseLabsLoaded">
        <b-button variant="warning" :disabled="!createButtonEnable" @click="createLabForCourse">Add Lab details to the course</b-button>
      </b-card>
      <b-card class="text-center mt-2" v-if="courseLabs.length == 1 && courseLabsLoaded">
        <b-button variant="warning" :disabled="!createButtonEnable" @click="createLabForCourse">Add Lab Exam details to the course</b-button>
      </b-card>
      <b-card class="text-center mt-2" v-if="courseLabs.length == 2 && courseLabsLoaded">
        <b-button variant="warning" :disabled="!createButtonEnable" @click="createLabForCourse">Add Lab Viva Voce to the course</b-button>
      </b-card>
      <b-card class="text-center mt-2" v-if="courseLabs.length == 3 && courseLabsLoaded">
        <b-button variant="warning" :disabled="!createButtonEnable" @click="createLabForCourse">Add Lab Record to the course</b-button>
      </b-card>
    </b-col>

    <b-modal id="experimentModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
      hide-header hide-footer centered size="md">
      <div>
        <b-col>
          <b-form-group id="input-group-1" label="No:" label-for="input-1">
            <b-form-input id="input-1" v-model="experiment.no" type="text" placeholder="Enter number">
            </b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Experiment:" label-for="input-2">
            <b-form-input id="input-2" v-model="experiment.name" placeholder="Enter experiment"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Description:" label-for="input-2">
            <b-form-input id="input-2" v-model="experiment.description" placeholder="Enter Description">
            </b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Procedure:" label-for="input-2">
            <b-form-input id="input-2" v-model="experiment.procedures" placeholder="Enter Procedure">
            </b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Course Outcomes:" label-for="input-2">
            <b-form-checkbox-group id="checkbox-group-2" v-model="experiment.cos" name="flavour-2">
              <b-form-checkbox v-for="(co, scindex) in courseOutcomeOptions" :key="scindex" :value="co.value">{{ co.text
                }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-button @click="updateLabExperiment()" :disabled="updateExperimentButton != 'Save'" variant="primary">
            {{ updateExperimentButton }}</b-button>
          <b-button @click="$bvModal.hide('experimentModel')" variant="danger">Cancel</b-button>
        </b-col>
      </div>
    </b-modal>

    <b-modal modal-class="labEvaluation" id="labEvaluationModel" show no-close-on-backdrop no-close-on-keyboard
      no-close-on-esc hide-header-close hide-header hide-footer>
      <b-col v-if="evaluationLabActivity">
        <div
          v-if="labInternalExamEvaluationType == 'multiple_experiments' && evaluationLabActivity.type == 'internal_exam'">
          <b-button class="my-2 p-1" variant="warning" @click="labInternalExamEvaluationType = 'single_experiment'">
            Click
          </b-button> to hide experiments list and enter a single score
        </div>
        <table class="table  table-striped table-bordered">
          <thead v-if="labInternalExamEvaluationType == 'multiple_experiments'"
            style=" position: sticky; top: 0;  background-color: gray; ">
            <tr>
              <th rowspan="2">No.</th>
              <th rowspan="2">Student</th>
              <th v-for="(labExperiment, expindex) in labExperiments" :key="expindex"
                :colspan="enabledLabHeaders.length+2">
                <span v-b-tooltip.hover :title="labExperiment.no +
                  '. ' +
                  labExperiment.name +
                  ': ' +
                  labExperiment.description
                  ">{{ labExperiment.no }}.
                  {{ labExperiment.name.substring(0, 15) }}</span>
              </th>
              <th rowspan="2">Avg</th>
            </tr>
            <tr>
              <th v-for="(header, headindex) in evaluationTableHeaderRow" :key="headindex">
                <span v-if="header.type == 'date'">{{evaluationLabActivity.type == 'lab'?'Date':''}}</span>
                <span v-else>{{ header.name }}-{{ header.max_score }}</span>
              </th>
            </tr>
          </thead>
          <thead v-if="(labInternalExamEvaluationType == 'single_experiment' && labExperiments.length > 0)"
            style=" position: sticky; top: 0;  background-color: gray; ">
            <tr>
              <th rowspan="2">No.</th>
              <th rowspan="2">Student</th>
              <th :colspan="enabledLabHeaders.length">
                Experiments score | <b-button class="ml-3 p-1" variant="warning"
                  @click="labInternalExamEvaluationType = 'multiple_experiments'"
                  v-if="evaluationLabActivity.type == 'internal_exam'">
                  Click
                </b-button> to list all experiments
              </th>
            </tr>
            <tr>
              <th v-for="(enabledLabHeader, headindex) in enabledLabHeaders" :key="headindex">
                {{ evaluationTableHeaderRow[headindex].name }}-{{ evaluationTableHeaderRow[headindex].max_score }}
              </th>
            </tr>
          </thead>

          <tbody v-if="labInternalExamEvaluationType == 'multiple_experiments'">
            <tr v-for="(student, studindex) in students" :key="studindex">
              <th class="fixed-table-column">{{ student.roll_number }}</th>
              <th class="fixed-table-column">
                {{ student.first_name }} {{ student.last_name }}
              </th>
              <td v-for="(evaluationHeader,
                headindex) in evaluationTableHeaderRow" :key="headindex" style="min-width: 60px;">
                <small style="font-size: 10px !important;"
                  v-if="evaluationHeader.type == 'date' && evaluationLabActivity.type == 'lab'">
                  {{evaluationTableDates[student.institution_user_id][
                  evaluationHeader.experiment_id
                  ]}}
                </small>
                <span v-if="evaluationHeader.type == 'date'">
                  <b-form-input v-if="evaluationLabActivity.type == 'lab'" style="width: 18px; padding: 0px;" v-model="evaluationTableDates[student.institution_user_id][
                    evaluationHeader.experiment_id
                  ]" type="date" placeholder="Choose score">
                  </b-form-input>
                </span>
                <b-form-input v-if="evaluationHeader.type == null" v-model="evaluationTableData[student.institution_user_id][
                      evaluationHeader.experiment_id
                    ][evaluationHeader.id]
                      " type="number" :max="evaluationHeader.max_score" placeholder="Enter score"
                      @change="updateTotalMarks(student.institution_user_id,evaluationHeader.experiment_id,evaluationHeader.id)"
                >
                </b-form-input>
                <span v-if="evaluationHeader.type == 'total'">{{totalScores[student.institution_user_id][evaluationHeader.experiment_id]?parseFloat(totalScores[student.institution_user_id][evaluationHeader.experiment_id]).toFixed(2):''}}
                </span>
            </td>
            <th class="fixed-table-column">
              {{avarage[student.institution_user_id]?parseFloat(avarage[student.institution_user_id]).toFixed(2):''}}
              </th>
            </tr>
          </tbody>
          <tbody v-if="(labInternalExamEvaluationType == 'single_experiment' && labExperiments.length > 0)">
            <tr v-for="(student, studindex) in students" :key="studindex">
              <th class="fixed-table-column">{{ student.roll_number }}</th>
              <th class="fixed-table-column">
                {{ student.first_name }} {{ student.last_name }}
              </th>
              <td v-for="(enabledLabHeader, headindex)  in enabledLabHeaders" :key="headindex" style="min-width: 60px;">
                <b-form-input v-model="evaluationTableData[student.institution_user_id][
                  evaluationTableHeaderRow[0].experiment_id
                ][evaluationTableHeaderRow[headindex].id]" type="number"                  :max="evaluationTableHeaderRow[headindex].max_score" placeholder="Enter score">
                </b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <b-button @click="updateLabEvaluation()" :disabled="updateLabEvaluationButton != 'Save'" variant="primary">
          {{ updateLabEvaluationButton }}</b-button>
        <b-button class="ml-4" @click="closelabEvaluationModel()" variant="danger">Cancel</b-button>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import CopyCourseProperties from "../templates/Dashboard/Course/CopyCourseProperties.vue"
import axios from "axios";
import Vue from 'vue';
export default {
  props: ["course_id"],
  async created() {
    await this.getCourseLabs();
    await this.getLabExperiments();
    if (this.courseLabs.length == 0) {
      return;
    }
    await this.getLabEvaluationOptions();
  },
  components: {
    CopyCourseProperties,
  },
  data() {
    return {
      createButtonEnable: true,
      totalScores: {}, // processed on updateTotalMarks computed proprty
      avarage:{},
      fetchData: false,
      copy_from: '',
      courseLabs: [],
      courseLabsLoaded: false,
      labExperiments: [],
      experiment: {
        id: "",
        no: "",
        name: "",
        description: "",
        cos: [],
        procedures: "",
        course_id: this.course_id,
      },
      labEvaluationOptions: {
        lab: {
          Practical: { id: "", enable: false, score: "", no: "" },
          Record: { id: "", enable: false, score: "", no: "" },
          Viva: { id: "", enable: false, score: "", no: "" },
          Algorithm: { id: "", enable: false, score: "", no: "" },
          Program: { id: "", enable: false, score: "", no: "" },
          Output: { id: "", enable: false, score: "", no: "" },
          LabWork: { id: "", enable: false, score: "", no: "" },
          ObservationAndResult: { id: "", enable: false, score: "", no: "" },
          AimAndProcedure: { id: "", enable: false, score: "", no: "" },
          OpenEndedExperiment: { id: "", enable: false, score: "", no: "" },
          Knowledge: { id: "", enable: false, score: "", no: "" },
          Experiment: { id: "", enable: false, score: "", no: "" },
          Documentation: { id: "", enable: false, score: "", no: "" },
          SafetyAndCleanup: { id: "", enable: false, score: "", no: "" },
          ToolsAndEquipment: { id: "", enable: false, score: "", no: "" },
          Procedure: { id: "", enable: false, score: "", no: "" },
          DataAndResult: { id: "", enable: false, score: "", no: "" },
          LabRecordAndGithub: { id: "", enable: false, score: "", no: "" },
          TimelyCompletionOfTask: { id: "", enable: false, score: "", no: "" },
          ClassViva: { id: "", enable: false, score: "", no: "" },
          Exam: { id: "", enable: false, score: "", no: "" },
        },
        internal_exam: {
          Practical: { id: "", enable: false, score: "", no: "" },
          Record: { id: "", enable: false, score: "", no: "" },
          Algorithm: { id: "", enable: false, score: "", no: "" },
          Program: { id: "", enable: false, score: "", no: "" },
          Output: { id: "", enable: false, score: "", no: "" },
          Viva: { id: "", enable: false, score: "", no: "" },
          CourseProject: { id: "", enable: false, score: "", no: "" },
          PreliminaryWork: { id: "", enable: false, score: "", no: "" },
          ImplementingTheWork: { id: "", enable: false, score: "", no: "" },
          Performance: { id: "", enable: false, score: "", no: "" },
          WrittenExam: { id: "", enable: false, score: "", no: "" },          
          Knowledge: { id: "", enable: false, score: "", no: "" },
          Experiment: { id: "", enable: false, score: "", no: "" },
          Documentation: { id: "", enable: false, score: "", no: "" },
          SafetyAndCleanup: { id: "", enable: false, score: "", no: "" },
          ToolsAndEquipment: { id: "", enable: false, score: "", no: "" },
          Procedure: { id: "", enable: false, score: "", no: "" },
          DataAndResult: { id: "", enable: false, score: "", no: "" },
          LabRecordAndGithub: { id: "", enable: false, score: "", no: "" },
          TimelyCompletionOfTask: { id: "", enable: false, score: "", no: "" },
          ClassViva: { id: "", enable: false, score: "", no: "" },
          Exam: { id: "", enable: false, score: "", no: "" },
        },

        record: {
          Record: { id: "", enable: false, score: "", no: "" },
        },
        viva_voce: { Viva: { id: "", enable: false, score: "", no: "" } },
      },
      editLabHeaders: false,
      updateExperimentButton: "save",
      enableLabAssessmentEvaluation: false,
      enableLabInternalExamEvaluation: false,
      enableLabRecordEvaluation: false,
      labInternalExamEvaluationType: 'single_experiment', // 'multiple_experiments', 'single_experiment'
      enableLabVivaVoceEvaluation: false,
      updateLabEvaluationButton: "Save",
      students: [],
      evaluationLabActivity: null,
      enabledLabHeaders: [],
      evaluationTableHeaderRow: [],
      evaluationTableDates:{},
      evaluationTableData: {},
      courseOutcomes: [],
      courseOutcomeOptions: [],
    };
  },
  methods: {
    updateTotalMarks(student_id, experiment_id) {
      let scores = this.evaluationTableData[student_id][experiment_id]
      const scoresArray = Object.keys(scores).map(function (key) {return [Number(key), scores[key]];});
      this.totalScores[student_id][experiment_id] = scoresArray.reduce((sum, score) => {return sum + Number(score[1]);}, 0);
      const total = this.totalScores[student_id];
      const totalArray = Object.keys(total).map(function (key) {return [Number(key), total[key]];});
      let totalScoresSum = totalArray.reduce((sum, score) => {return sum + Number(score[1]);}, 0);
      let count = 0;
      totalArray.forEach((total) => {
        if (total[1]) {
          count = count + 1;
        }
      })
      this.avarage[student_id] = totalScoresSum / count;
    },
    handleStudExperimentDateChange( studId,expId){
      document.getElementById('studExperimentDatePicker'+studId+expId).click();
    },
    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel');
      this.getLabExperiments();
    },
    async getCourseLabs() {
      this.createButtonEnable = true;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labactivities?course=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.courseLabs = response.data;
          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
      this.courseLabsLoaded = true;
    },
    async createLabForCourse() {
      this.createLabForCourseButton = false;
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/labactivities`;
      await axios
        .post(url, {
          course_id: this.course_id,
        })
        .then(() => {
          this.getCourseLabs();
          // this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to initiate lab, Please try again", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
    },
    async getLabEvaluationOptions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labevaluationheaders?course=${this.course_id}`;
      await axios.get(url).then((response) => {
        let labevaluationheaders = response.data;
        this.courseLabs.forEach((lab) => {
          labevaluationheaders.forEach((header) => {
            if (header.lab_activity_id == lab.id) {
              if (lab.type == "lab") {
                this.enableLabAssessmentEvaluation = true;
              } else if (lab.type == "internal_exam") {
                // internal_exam
                this.enableLabInternalExamEvaluation = true;
              } else if (lab.type == "record") {
                // internal_exam
                this.enableLabRecordEvaluation = true;
              } else {
                // viva_voce
                this.enableLabVivaVoceEvaluation = true;
              }
              this.labEvaluationOptions[lab.type][header.name].score =
                header.maximum_mark;
              this.labEvaluationOptions[lab.type][header.name].id = header.id;
              this.labEvaluationOptions[lab.type][header.name].no = header.no;
              this.labEvaluationOptions[lab.type][header.name].enable = true;
            }
          });
        });
      });
    },
    async updateLabEvaluationOptions(courseLabType, labId) {
      if (courseLabType == "lab") {
        this.enableLabAssessmentEvaluation = false;
      } else if (courseLabType == "internal_exam") {
        this.enableLabInternalExamEvaluation = false;
      } else if (courseLabType == "record") {
        this.enableLabRecordEvaluation = false;
      } else {
        // viva voce
        this.enableLabVivaVoceEvaluation = false;
      }
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/labevaluationheaders`;
      var postData = [];

      for (var headerKey in this.labEvaluationOptions[courseLabType]) {
        // console.log(this.labEvaluationOptions[courseLabType][headerKey]);
        if (this.labEvaluationOptions[courseLabType][headerKey].enable) {
          if (courseLabType == "lab") {
            this.enableLabAssessmentEvaluation = true;
          } else if (courseLabType == "internal_exam") {
            this.enableLabInternalExamEvaluation = true;
          } else if (courseLabType == "record") {
            this.enableLabRecordEvaluation = true;
          } else {
            // viva_voce
            this.enableLabVivaVoceEvaluation = true;
          }
          if (
            this.labEvaluationOptions[courseLabType][headerKey].score == "" ||
            this.labEvaluationOptions[courseLabType][headerKey].no == ""
          ) {
            this.$toast.error("Please fill all enabled fields", {
              position: "top",
              duration: 3000,
            });
          }

          postData.push({
            maximum_mark: this.labEvaluationOptions[courseLabType][headerKey]
              .score,
            lab_activity_id: labId,
            name: headerKey,
            no: this.labEvaluationOptions[courseLabType][headerKey].no,
          });
        }
      }
      await axios
        .post(url, { labHeaders: postData, lab_activity_id: labId })
        .then((response) => {
          response;
          // let labevaluationheaders = response.data;
          // this.courseLabs.forEach(lab => {
          //   labevaluationheaders.forEach(header => {
          //     if (header.lab_activity_id == lab.id) {
          //       this.labEvaluationOptions[lab.type][header.name].score = header.max_score;
          //       this.labEvaluationOptions[lab.type][header.name].enable = true;
          //     }
          //   });
          // });
        })
        .catch(() => {
          this.$toast.error(
            "Failed to update/ you cannot delete if Score is already added",
            {
              position: "top",
              duration: 5000,
            }
          );
          this.fetchData = false;
        });
      await this.getLabEvaluationOptions();
      this.editLabHeaders = false;
    },
    async getLabExperiments() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labexperiments?course=${this.course_id}`;
      await axios.get(url).then((response) => {
        this.labExperiments = response.data;
      });
    },
    async createLabExperiment() {
      this.experiment = {
        id: "",
        no: "",
        name: "",
        description: "",
        cos: [],
        procedures: "",
        course_id: this.course_id,
      };
      this.updateExperimentButton = "Save";
      this.$bvModal.show("experimentModel");
      this.getcourseOutcomes();
    },
    async editLabExperiment(experiment) {
      this.updateExperimentButton = "Save";
      this.experiment = experiment;
      let coIds = [];
      if (experiment.course_outcomes) {
        experiment.course_outcomes.forEach(co => {
          coIds.push(co.id);
        });
      }
      this.experiment.cos = coIds;
      this.$bvModal.show("experimentModel");
      this.getcourseOutcomes();
    },
    async updateLabExperiment() {
      this.updateExperimentButton = "Saving...";
      if (this.experiment.id == "") {
        // create mode
        const url = this.$store.getters.getAPIKey.mainAPI + `/labexperiments`;
        await axios
          .post(url, this.experiment)
          .then(() => {
            this.getLabExperiments();
            // this.fetchData = false;
          })
          .catch(() => {
            this.$toast.error("Failed to create", {
              position: "top",
              duration: 3000,
            });
            this.fetchData = false;
          });
      } else {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/labexperiments/${this.experiment.id}`;
        await axios
          .put(url, this.experiment)
          .then(() => {
            this.getLabExperiments();
            // this.fetchData = false;
          })
          .catch(() => {
            this.$toast.error("Failed to update", {
              position: "top",
              duration: 3000,
            });
            this.fetchData = false;
          });
      }

      this.$bvModal.hide("experimentModel");
    },
    async deleteLabExperiment(experiment) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labexperiments/${experiment.id}`;
      await axios
        .delete(url)
        .then(() => {
          this.$toast.warning("Experiment Deleted", {
            position: "top",
            duration: 3000,
          });
          this.getLabExperiments();
          // this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error(
            "Failed to delete/ you cannot delete if Score is already added",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.fetchData = false;
        });
    },
    closelabEvaluationModel() {
      this.$bvModal.hide("labEvaluationModel");
    },
    async labEvaluationTable(labActivity) {
      if (labActivity.type == "internal_exam") {
        this.labInternalExamEvaluationType = 'single_experiment';
      } else {
        this.labInternalExamEvaluationType = 'multiple_experiments';
      }
      this.evaluationLabActivity = labActivity;
      this.evaluationTableHeaderRow = [];
      this.enabledLabHeaders = [];
      this.evaluationTableDates = {};
      // for evaluation we need student list, experiment list, header list
      if (this.students.length == 0) await this.fetchCourseStudents();
      for (var labHeaderKey in this.labEvaluationOptions[labActivity.type]) {
        if (this.labEvaluationOptions[labActivity.type][labHeaderKey].enable) {
          this.enabledLabHeaders.push(
            this.labEvaluationOptions[labActivity.type][labHeaderKey]
          );
        }
      }
      this.labExperiments.forEach((exp) => {
        let sum =0;
        for (var labHeaderKey in this.labEvaluationOptions[labActivity.type]) {
          if (
            this.labEvaluationOptions[labActivity.type][labHeaderKey].enable
          ) {
            sum= Number(sum)+Number( this.labEvaluationOptions[labActivity.type][
                labHeaderKey
              ].score);
            this.evaluationTableHeaderRow.push({
              id: this.labEvaluationOptions[labActivity.type][labHeaderKey].id,
              name: labHeaderKey,
              experiment_id: exp.id,
              max_score: this.labEvaluationOptions[labActivity.type][
                labHeaderKey
              ].score,
              type:null
            });
          }
        }
        this.evaluationTableHeaderRow.push({
          id: null,
          name: 'Total',
          experiment_id: exp.id,
          max_score: sum,
          type:'total'
        });
        this.evaluationTableHeaderRow.push({
          id: null,
          name: null,
          experiment_id: exp.id,
          max_score: null,
          type:'date'
        });
      });

      this.students.forEach((student) => {
        this.evaluationTableData[student.institution_user_id] = {};
        Vue.set(this.totalScores, student.institution_user_id, {});
        Vue.set(this.avarage, student.institution_user_id, {});
        this.evaluationTableHeaderRow.forEach((evaluationHeader) => {
          if(evaluationHeader.type=='date'){
            if(!(student.institution_user_id in this.evaluationTableDates))this.$set(this.evaluationTableDates, student.institution_user_id, {});
            if(!(evaluationHeader.experiment_id in this.evaluationTableDates[student.institution_user_id]))this.$set(this.evaluationTableDates[student.institution_user_id], evaluationHeader.experiment_id, null);
          }
          else{
          if ( !(
              evaluationHeader.experiment_id in
              this.evaluationTableData[student.institution_user_id]
            ) )
            this.evaluationTableData[student.institution_user_id][
              evaluationHeader.experiment_id
            ] = {};
            this.evaluationTableData[student.institution_user_id][
              evaluationHeader.experiment_id
            ][evaluationHeader.id] = null;
            Vue.set(this.totalScores[student.institution_user_id], evaluationHeader.experiment_id, null);
          }
        });
      });
      // this.$forceUpdate();
      this.$bvModal.show("labEvaluationModel");
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labevaluations?labActivity=${labActivity.id}`;
      await axios.get(url).then((response) => {
        let multipleExperimentEvaluation = null;
        response.data.evaluations.forEach((evaluation) => {
          if (this.evaluationLabActivity.type == "internal_exam") {
            if (multipleExperimentEvaluation != null && multipleExperimentEvaluation != evaluation.experiment_id) {
              this.labInternalExamEvaluationType = 'multiple_experiments';
            }
          }
          // console.log(evaluation);
          if (evaluation.student_id in this.evaluationTableData)
            this.evaluationTableData[evaluation.student_id][
              evaluation.experiment_id
            ][evaluation.header_id] = parseFloat(evaluation.score);
            this.updateTotalMarks(evaluation.student_id, evaluation.experiment_id);
            multipleExperimentEvaluation = evaluation.experiment_id;
        });
        response.data.dates.forEach((date) => {
          if (date.student_id in this.evaluationTableDates)
            this.evaluationTableDates[date.student_id][
            date.experiment_id
            ] = date.date;
        });
        
      });
      this.$forceUpdate();
    },
    async updateLabEvaluation() {
      var postData = [];
      for (const studkey in this.evaluationTableData) {
        for (const expdkey in this.evaluationTableData[studkey]) {
          for (const headdkey in this.evaluationTableData[studkey][expdkey]) {
            let score = this.evaluationTableData[studkey][expdkey][headdkey];
            if (score !== null) {
              postData.push({
                score: score,
                course_id: this.course_id,
                student_id: studkey,
                lab_activity_id: this.evaluationLabActivity.id,
                experiment_id: expdkey,
                header_id: headdkey,
              });
            }
          }
        }
      }
      let experimentDates = [];
      for (const studkey in this.evaluationTableDates) {
        for (const expdkey in this.evaluationTableDates[studkey]) {
          let date = this.evaluationTableDates[studkey][expdkey];
          if (date != null && date !='') {
            experimentDates.push({
              date: date,
              student_id: studkey,
              lab_activity_id: this.evaluationLabActivity.id,
              experiment_id: expdkey,
            });
          }
        }
      }
      const url = this.$store.getters.getAPIKey.mainAPI + `/labevaluations`;
      await axios
        .post(url, { labEvaluations: postData,experiment_dates: experimentDates })
        .then(() => {
          // this.getLabExperiments();
          // this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to create", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });

      this.$bvModal.hide("labEvaluationModel");
    },
    async fetchCourseStudents() {
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", this.course_id);
      await this.$axios.get(url).then((response) => {
        this.students = [];
        this.students = response.data[0];
      });
    },

    async getcourseOutcomes() {
      if (this.courseOutcomes.length > 0) {
        return this.courseOutcomes;
      }
      // this.courseOutcomeOptions.push({ value: null, text: "Choose Co's" });
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.course_id);
      await axios
        .get(url)
        .then((response) => {
          this.courseOutcomes = response.data;
          this.courseOutcomes.forEach(co => {
            this.courseOutcomeOptions.push({ value: co.id, text: co.code });
          });
        });
      return this.courseOutcomes;
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}

.labEvaluation .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.labEvaluation .modal-body {
  overflow: scroll;
}

.labEvaluation .fixed-table-column {
  position: sticky;
  left: 0;
  background-color: gray;
}
</style>
