<template>
  <div>

    <b-col sm="12">

      <div class="row justify-content-center">
        <div class="table-container ">
          <b-col class="border border-dark py-2">
            <div>
              <b-row class="d-flex justify-content-between">
                <ul>
                  <li style="border:none;">Mark Attendance Using Calender? <b-link
                      @click="$bvModal.show('customDateAttendanceMarkModel')"> Click here to proceed</b-link>
                  </li>
                  <li style="border:none;">Want To Edit/Delete Attendance? <b-link
                      @click="openEditCourseAttendanceFormWithReportMainTab"> Click here to proceed</b-link>
                  </li>
                  <li style="border:none;">Download my schedule pdf report<b-link @click="downloadMySchedulePdf">
                      {{ loadingPdf ? 'Please wait...' : 'Click to download' }}</b-link>
                  </li>
                </ul>

                <ul class="mr-2">
                  <li style="border:none; margin-bottom: 1px;"> <span class="px-3  mr-1 border-success"
                      style="border: 2px solid ;"></span>Scheduled, Attendance Marked
                  </li>
                  <li style="border:none;margin-bottom: 1px;"> <span class="px-3  mr-1 border-danger"
                      style="border: 2px solid;"></span>Scheduled, Attendance Not Marked
                  </li>
                  <li style="border:none;margin-bottom: 1px;"> <span class="px-3  mr-1 border-warning"
                      style="border: 2px solid ;"></span>Scheduled, Attendance Marked by Another Faculty
                  </li>
                  <li style="border:none;margin-bottom: 1px;"> <span class="px-3  mr-1 border-info"
                      style="border: 2px solid ;"></span>Not Scheduled, Attendance Marked.
                  </li>

                  <li style="border:none;margin-bottom: 1px;"> <span class="px-3 holiday-schedule  mr-1 border-dark"
                      style="border: 2px solid;"></span>Holiday.
                  </li>
                  <li style="border:none;margin-bottom: 1px;"> <span class="px-3 suspended-schedule  mr-1 border-dark"
                      style="border: 2px solid;"></span>Class Suspended.
                  </li>
                </ul>
              </b-row>
              <b-modal id="customDateAttendanceMarkModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
                hide-header-close hide-footer hide-header centered size="md">
                <customDateAttendanceMarking :sessionsProp="sessions"
                  @markNewAttendance="markNewAttendanceFromCustomDate" />
              </b-modal>
            </div>
            <h5 class="text-center" style="font-size:16px !important; color: #010177;">
              My Schedule
            </h5>
          </b-col>
          <table v-if="(viewMySchedule === true)" class="table table-bordered black-border "
            style="width: auto !important; text-align: center;">
            <thead style="position: sticky;top: 0;">
              <tr>
                <th :colspan="(mySchedulesEnabledSessionIds.length + 1)" style="color: #000950;"
                  class="text-center  bg-white">
                  <span v-if="selectedSlots.course_id == ''">Please choose slots to mark attendance</span>
                  <span v-if="selectedSlots.course_id != ''">
                    <span class="text-info" v-if="selectedSlots.holiday">This date is already marked as holiday. Do you
                      still want to proceed with attendance marking?<br></span>
                    <span class="text-info" v-if="selectedSlots.suspend">This session has already been suspended. Do you
                      still want to proceed with attendance marking?<br></span>
                    <i class="blink_me fas fa-calendar mr-3"></i>

                    {{ selectedSlots.already_marked ? 'Edit ' : 'Mark new '
                    }}attendance for
                    {{ courses[selectedSlots.course_id].name }}-{{ courses[selectedSlots.course_id].usersubgroup.code }}
                    on
                    {{ moment(selectedSlots.date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                    and sessions:
                    <span v-for="sessionId in selectedSlots.sessions" :key="sessionId">{{ sessionObjects[sessionId].name
                      }},</span>
                    <b-button class="ml-2" variant="success" size="sm" @click="markNewAttendance">Proceed</b-button>
                  </span>
                  <span v-b-tooltip.hover :title="'Refresh Schedule Table'" style="float: right;"
                    @click="getMySchedulesAttendances()">
                    <i class="fas fa-sync"></i>
                  </span>

                </th>
              </tr>
              <tr>
                <th class="text-center text-white bg-dark" style="min-width: 100px;">
                  <i class="fas fa-user-clock fa-lg"></i>
                </th>
                <th v-for="sessionId in mySchedulesEnabledSessionIds" :key="sessionId" style="min-width: 100px;"
                  class="text-center text-white bg-dark">
                  {{ sessionObjects[sessionId].name }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(sessionsScheduleAtts, date) in dateSessionScheduleAtts"
                :ref="(new Date().toISOString().slice(0, 10) == date) ? 'todaySchedule' : ''" :key="date" style="">
                <td>
                  {{ moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') }} <br>{{ weekdayNames[new Date(date).getDay()] }}
                </td>

                <td
                  :class="{ 'selected-slot': (selectedSlots.sessions.includes(sessionId) && selectedSlots.date == date) }"
                  v-for="sessionId in mySchedulesEnabledSessionIds" :key="sessionId" style="padding: 1px">

                  <div style="cursor: pointer; margin: 1px;"
                    v-for="(scheduleData, scheduleIndex) in sessionsScheduleAtts[sessionId].schedules"
                    :key="scheduleIndex"
                    @click="selectScheduleSlot(date, sessionId, scheduleData.course_id, scheduleData.attendance_status, scheduleData.holiday != null, scheduleData.suspend != null)"
                    v-b-popover.hover.top="(scheduleData.suspend != null ? ' Class Suspended: ' + scheduleData.suspend + ', ' : '') +
                (scheduleData.holiday != null ? 'Holiday: ' + scheduleData.holiday + ', ' : '') +
                courses[scheduleData.course_id].name +
                '-' +
                courses[scheduleData.course_id].usersubgroup.name +
                '-' + courses[scheduleData.course_id].usersubgroup.usergroup.name
                + (scheduleData.attendance_status == '' ? '' :
                  ((scheduleData.attendance_status == 'marked_for_schedule_by_someone' ?
                    (' marked by' + scheduleData.mark_by_someone) : ' marked by yourself')))" :class="{
        'c-border border-danger': (scheduleData.attendance_status == ''),
        'c-border border-success text-success': (scheduleData.attendance_status == 'marked_for_schedule'),
        'c-border border-warning text-success': (scheduleData.attendance_status == 'marked_for_schedule_by_someone'),
        'holiday-schedule': scheduleData.holiday != null, 'suspended-schedule': scheduleData.suspend != null
      }">
                    <span v-if="scheduleData.class_type" style="color : red;font-size:8px !important;">
                      {{ scheduleData.class_type }}<br></span> {{ courses[scheduleData.course_id].code }}-{{
                        courses[scheduleData.course_id].usersubgroup.code }}<span
                      style="color : blue;font-size:8px !important;" v-if="scheduleData.delivery_method"><br>{{
                        scheduleData.delivery_method }}</span>
                  </div>

                  <!-- marked non chedule courses  -->
                  <div v-if="(sessionsScheduleAtts[sessionId].non_schedule_marked_courses.length > 0)"
                    style="height: 35px;">
                    <div @click="selectScheduleSlot(date, sessionId, courseId,'marked_for_not_scheduled', null,null)"
                      style="cursor: pointer; margin: 1px;" class="c-border border-info"
                      v-for="(courseId, courseIndex) in sessionsScheduleAtts[sessionId].non_schedule_marked_courses"
                      :key="courseIndex" v-b-popover.hover.top="
                        courses[courseId].name +
                        '-' +
                        courses[courseId].usersubgroup.name + '-' + courses[courseId].usersubgroup.usergroup.name + ' marked by yourself out of schedule. please use edit attendance at the top to edit this attendance '
                      ">
                      {{ courses[courseId].code }}-{{ courses[courseId].usersubgroup.code }}
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="(viewMySchedule === null)">Loading Schedules...</div>
      <div v-if="(viewMySchedule === false)">Timetable is not updated by Faculty adviser</div>
    </b-col>
    <b-modal id="studentsAttendanceMarkingFormModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
      hide-footer hide-header-close hide-title modal-class="markAttendence">
      <studentsAttendanceMarkingForm :dataToMarkAttendanceFor="markAttendanceFor"
        @successAttendanceMarkForm="successAttendanceMarkForm" @cancelAttendanceMarkForm="cancelAttendanceMarkForm" />
    </b-modal>
  </div>
</template>

<script>
import studentsAttendanceMarkingForm from './studentsAttendanceMarkingForm.vue';
// import moment from "moment";
import customDateAttendanceMarking from './customDateAttendanceMarking.vue';
export default {
  props: ["propSessions"],
  async created() {
    // this.sessions = this.propSessions;
    // if (this.sessions.length < 1) {
    await this.getSessions();
    // } else {
    //   this.sessionObjects = {};
    //   this.sessions.forEach(session => {
    //     this.sessionObjects[session.id] = session;
    //   });
    // }
    await this.getMySchedulesAttendances();
  },
  components: {
    customDateAttendanceMarking,
    studentsAttendanceMarkingForm,
  },
  data() {
    return {
      viewMySchedule: null,
      dateSessionScheduleAtts: {},
      courses: {},
      sessions: [],
      sessionObjects: {},
      mySchedulesEnabledSessionIds: [],
      // badgeColors: [
      //   'badge-red', 'badge-orange', 'badge-yellow', 'badge-green', 'badge-blue', 'badge-pink', 'badge-purple', 'badge-brown', 'badge-black', 'badge-white'
      // ],
      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      selectedSlots: {
        date: '',
        sessions: [],
        course_id: '',
        // confirm_text: '', // pass to att mark form
        already_marked: false,
        holiday: false,
        suspend: false,
      },
      markAttendanceFor: { // dedicated to pass data to  attendance mark form from this and other componant
        date: '',
        sessions: [],
        course_id: '',
        confirm_text: '',
        // already_marked: false,
      },
      loadingPdf: false,
    };
  },
  methods: {
    async downloadMySchedulePdf() {
      this.loadingPdf = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courseschedules/report/pdfexport`;
      await this.$axios
        .post(
          url, {}, { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "faculty_personal_schedules.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;
        })
        .catch(() => {
          this.$toast.error('Failed to download', {
            position: "top",
            duration: 7000,
          });
        });
      this.loadingPdf = false;
    },
    openEditCourseAttendanceFormWithReportMainTab() {
      // open in Main Content area 
      this.$root.subContent.subContentComponent = "editCourseAttendanceFormWithReport";
      this.$root.subContent.subContentData = {};
      this.$root.subContent.enableMainContent = false;
    },
    markNewAttendanceFromCustomDate(selectedSlots) {
      this.$bvModal.hide('customDateAttendanceMarkModel');
      this.markAttendanceFor = selectedSlots;
      this.$bvModal.show('studentsAttendanceMarkingFormModel');
      // this.$emit('markNewAttendance', selectedSlots)
    },
    markNewAttendance() {
      // this.selectedSlots;
      let tempDate = new Date(this.selectedSlots.date).toLocaleDateString();
      let sessionNames = '';
      let tempCourse = this.courses[this.selectedSlots.course_id];
      this.selectedSlots.sessions.forEach(sessionId => {
        sessionNames += this.sessionObjects[sessionId].name + ', '
      });
      this.selectedSlots.confirm_text = 'Mark attendance for ' +
        tempCourse.name + ' '
        + tempCourse.usersubgroup.code + ' on ' + tempDate + ' and sessions: ' + sessionNames;

      // this.$emit('markNewAttendance', this.selectedSlots)
      this.markAttendanceFor = this.selectedSlots;
      this.$bvModal.show('studentsAttendanceMarkingFormModel');
    },
    selectScheduleSlot(date, sessionId, courseId, attendance_status, holiday, suspend) {

      this.selectedSlots.holiday = false;
      this.selectedSlots.suspend = false;
      // when select a slot if already selection these , 
      // then clear that id different date, diff course
      if (this.selectedSlots.date != date)
        this.clearSlotSelection();
      if (this.selectedSlots.course_id != courseId)
        this.clearSlotSelection();

      // now selection is fresh (data cleared) or for same date same course

      // set session if not in selection
      if (this.selectedSlots.sessions.includes(sessionId)) {
        this.selectedSlots.sessions.splice(this.selectedSlots.sessions.indexOf(sessionId), 1);
      } else {
        this.selectedSlots.sessions.push(sessionId);
        this.selectedSlots.holiday = holiday;
        this.selectedSlots.suspend = suspend;
      }

      this.selectedSlots.date = date;
      this.selectedSlots.course_id = courseId;
      this.selectedSlots.already_marked = attendance_status == '' ? false : true;

      if (this.selectedSlots.sessions.length < 1) {
        this.clearSlotSelection();
      }
      this.$forceUpdate();
    },

    clearSlotSelection() {
      this.selectedSlots.date = '';
      this.selectedSlots.sessions = [];
      this.selectedSlots.course_id = '';
      this.selectedSlots.confirm_text = '';
      this.selectedSlots.already_marked = false;
      this.selectedSlots.holiday = false;
      this.selectedSlots.suspend = false;
      this.markAttendanceFor.date = '';
      this.markAttendanceFor.sessions = [];
      this.markAttendanceFor.course_id = '';
      this.markAttendanceFor.confirm_text = '';
      // this.markAttendanceFor.already_marked= false;
    },

    // selectNonScheduleSlot(date, sessionId, courseId) {
    //   if (this.selectedSlots.date == date) { // select slot on same date then add / remove session only
    //     if (this.selectedSlots.sessions.includes(sessionId)) {
    //       this.selectedSlots.sessions.splice(this.selectedSlots.sessions.indexOf(sessionId), 1);
    //     } else {
    //       this.selectedSlots.sessions.push(sessionId);
    //     }
    //     if (this.selectedSlots.sessions.length < 1) { // clear all
    //       this.selectedSlots.date = '';
    //       this.selectedSlots.sessions = [];
    //       this.selectedSlots.course_id = '';
    //     }
    //   } else { // selected on a different date then reset date course sessions
    //     this.selectedSlots.date = '';
    //     this.selectedSlots.sessions = [];
    //     this.selectedSlots.course_id = '';

    //     this.selectedSlots.date = date;
    //     this.selectedSlots.sessions = [sessionId];
    //     this.selectedSlots.course_id = courseId;
    //   }
    //   this.$forceUpdate();
    // },
    async getMySchedulesAttendances() {
      if (this.sessions.length < 1) await this.getSessions();
      this.viewMySchedule = null;
      this.dateSessionScheduleAtts = {};
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroupschedules/myschedulesattendances`;
      var responseData = [];
      await this.$axios
        .get(url)
        .then((response) => {
          responseData = response.data;
        })
        .catch((error) => {
          error;
          this.viewMySchedule = false;
        });

      this.viewMySchedule = true;
      if (responseData.emptySchedule)
        this.viewMySchedule = false;

      let tempMySchedulesEnabledSessionIds = [];
      for (const date in responseData.dateSessionScheduleAtts) {
        const sessionsData = responseData.dateSessionScheduleAtts[date];
        for (const sessionId in sessionsData) {
          // const sessionData = sessions[sessionId];
          if (!tempMySchedulesEnabledSessionIds.includes(sessionId))
            tempMySchedulesEnabledSessionIds.push(sessionId);
        }
      }

      // reorder schedule sessions ids
      this.mySchedulesEnabledSessionIds = [];
      this.sessions.forEach(session => {
        if (tempMySchedulesEnabledSessionIds.includes(`${session.id}`))
          this.mySchedulesEnabledSessionIds.push(`${session.id}`);
      });

      this.dateSessionScheduleAtts = responseData.dateSessionScheduleAtts;
      this.courses = responseData.courses;
      // setTimeout(() => {
      //   this.scrollToToday()
      // }, 1500);
    },

    async getSessions() {
      this.sessionObjects = {};
      const url = this.$store.getters.getAPIKey.createSessions;
      await this.$axios
        .get(url)
        .then((response) => {
          this.sessions = response.data;
          this.sessions.forEach(session => {
            this.sessionObjects[session.id] = session;
          });
        })
        .catch((error) => {
          error;
        });
    },
    scrollToToday() {
      let el = this.$refs.todaySchedule;
      el.scrollIntoView({ behavior: "smooth" });
    },
    successAttendanceMarkForm() {
      this.$bvModal.hide('studentsAttendanceMarkingFormModel');
      this.clearSlotSelection();
      // this.selectedSlots.date = '';
      // this.selectedSlots.sessions = [];
      // this.selectedSlots.course_id = '';
      // this.markAttendanceFor.date = '';
      // this.markAttendanceFor.sessions = [];
      // this.markAttendanceFor.course_id = '';
    },
    cancelAttendanceMarkForm() {
      this.$bvModal.hide('studentsAttendanceMarkingFormModel');
      this.clearSlotSelection();
      // this.markAttendanceFor.date = '';
      // this.markAttendanceFor.sessions = [];
      // this.markAttendanceFor.course_id = '';
    },
  },
};
</script>

<style  >
.c-border {
  border: 2px solid;
}

.holiday-schedule {
  background-color: rgb(223, 219, 97);
}

.suspended-schedule {
  background-color: rgb(255, 126, 126);
}

.selected-slot {
  background-color: rgb(186, 186, 186) !important;
}

.table-container {
  max-height: 90vh !important;
  /* Adjust the maximum height as needed */
  overflow-y: auto !important;
}

.black-border th,
.black-border td,
.black-border tr {
  border: 1px solid rgb(175, 175, 175);
}

.blink_me {
  animation: blinker 1.5s linear infinite;
  color: forestgreen;
}
</style>
