
export const JoditViewFullButtons = [
    'undo', // Undo
    'redo', // Redo
    '|', // Separator
    'cut', // Cut
    'copy', // Copy
    'paste', // Paste
    '|', // Separator
    'bold', // Bold
    'italic', // Italic
    'underline', // Underline
    'strikethrough', // Strikethrough
    '|', // Separator
    'superscript', // Superscript
    'subscript', // Subscript
    '|', // Separator
    'ul', // Unordered list
    'ol', // Ordered list
    '|', // Separator
    'outdent', // Decrease indent
    'indent', // Increase indent
    '|', // Separator
    'font', // Font
    'fontsize', // Font size
    'brush', // Text color
    'paragraph', // Paragraph format
    '|', // Separator
    // 'image', // Image
    'table', // Table
    // 'link', // Insert link
    // 'unlink', // Remove link
    // 'video', // Video
    '|', // Separator
    'hr', // Horizontal line
    '|', // Separator
    '|', // Separator
    'copyformat', // Copy format
    // 'pasteformat', // Paste format
    '|', // Separator
    'source', // Source code view
    'fullsize', // Maximize editor
    'symbols',
    'eraser',
  ];
// export const MAX_RESULTS = 10;